import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageCaption = ({images}) => {
	return (
	<div className="image--caption p20 m-p0">
		<div className='container ma'>
			{images.map((image, index) => { 
		      return (
		      	<div key={index}>
		        <div className='max-1000 ma'>
					<div className='ratio-1-3 bg-grey pos-rel'>
						<GatsbyImage className="bg-image " image={image.image.gatsbyImageData} alt="Gallery Image" />
					</div>
				</div>

				<Spacer className='m-hide' />
				</div>
		      )
		    })}
		</div>
	</div>
	)
}
export default ImageCaption
