import * as React from "react"

const Quote = ({quote, author}) =>  {
	return (
		<div className='quote p20 m-pt0'>
			<div className='container ma'>
				<div className='max-800 ma text-center fade--in' data-sal data-sal-delay='300'>
					<p className='m0 h1'>{quote}</p>
					<p className='m0 mt20' dangerouslySetInnerHTML={{ __html: author }} />
				</div>
			</div>
		</div>
	)
}

export default Quote
