import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Gallery = ({images}) => {
	return (
	<div className="image--slider">
		{( images.length > 0 ?
		<div className='max-1600 ma'>
	      	<>
	      	<div className='swiper-container pos-rel'>
		      	<Swiper spaceBetween={0} slidesPerView={1} speed={1000}
			      	onSlideChange={() => {
		      			setTimeout(function(){ 
		      				document.querySelector('.slider-navigation span').innerHTML = document.querySelector('.swiper-slide-active').getAttribute('data-slide');
		      	  	}, 100);}}
		      	>

			   		{images.map((image, index) => { return (
						<SwiperSlide key={index} data-slide={index+1}> 
							<div className='ratio-1-3 bg-grey pos-rel'>
								<GatsbyImage className="bg-image " image={image.gatsbyImageData} alt="Gallery Image" />
							</div>
						</SwiperSlide>
					)})}
			    </Swiper>
			    <div className='slider-navigation small'><div className='ma'><span>1</span>/{images.length}</div></div>
			</div>
			<Spacer />
			</>
		</div> : '' )}
	</div>
	)
}
export default Gallery
