import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import HeroBanner from "../components/heroBanner"
import ContentBlock from "../components/contentBlock"
import ImageCaption from "../components/imageCaption"
import ProjectNavigation from "../components/projectNavigation"
import Gallery from "../components/gallery"
import Spacer from "../components/spacer"
import Footer from "../components/footer"
import Quote from "../components/quote"
import Seo from "../components/seo"
import { gsap } from "gsap"

export default function Project({ data }) {
  data = data.datoCmsTrkProject;
  useEffect(() => {
    if(window.innerWidth < 768) {
      document.querySelector('main').classList.add('will-fade');
      setTimeout(function(){
        gsap.to('main',{opacity:1, duration: 1});
      }, 500);
    }
    setTimeout(function(){
    document.querySelector('.hero--banner').classList.add('loaded');
    }, 100);
    document.querySelector('.back--button').classList.remove('hide');
    return () => {
      document.querySelector('.back--button').classList.add('hide');
    }
  },[])
  return (
    <Layout>
      <Seo title={data.title} />
      <HeroBanner image={data.image} className='scaled' />
      <div className='sticky-wrapper pos-rel'>
        <ProjectNavigation />
        <Spacer />
        <Quote quote={data.quote} author={data.author}  />
        <Spacer />
        <ContentBlock content={data.content} />
        <Spacer />
        <ImageCaption images={data.imageCaption} />
        <Gallery images={data.imageGallery} />
        <ImageCaption images={data.imageCaptionTwo} />
        <div className='m-show p20'>
          <div className='m-pl10 m-pr10'>
            <div className='grey'>Architects</div>
            <a href='https://rothelowman.com.au' target="_blank" rel="noreferrer" className='ul link hover inline dark'>Rothelowan</a>
            <br/><br/>
            <div className='grey'>Project Manager</div>
            <a href='https://www.bluebirdproperty.com.au' target="_blank" rel="noreferrer" className='ul hover inline link dark'>Bluebird Property</a>
          </div>
        </div>
        <Spacer className='m-show'/>
        <Spacer className='m-show'/>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query Project($id :String!) {
    datoCmsTrkProject(id:{eq:$id}){
      title  
      image {
        gatsbyImageData
      } 
      quote
      author
      content
      imageCaption {
        caption
        image {
          gatsbyImageData
        }
      }
      imageGallery {
        gatsbyImageData
      }
      imageCaptionTwo {
        caption
        image {
          gatsbyImageData
        }
      }
    }
  }
`
