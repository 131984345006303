import * as React from "react"

const ProjectNavigation = () => {
	return (
	<div className='fixed-navigation m-hide tablet-small'>
      <div className='fixed'>
        <div className='grey'>Architects</div>
        <a href='https://rothelowman.com.au' target="_blank" rel="noreferrer" className='ul link hover inline dark'>Rothelowan</a>
        <br/><br/>
        <div className='grey'>Project Manager</div>
        <a href='https://www.bluebirdproperty.com.au' target="_blank" rel="noreferrer" className='ul hover inline link dark'>Bluebird Property</a>
      </div>
    </div>
	)
}	

export default ProjectNavigation